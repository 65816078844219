.box {
  position: sticky;
  top: 70%;
  margin-left: auto;
  margin-right: auto;
  align-self: end;
  animation-duration: 2s;
  animation-iteration-count: infinite;

  background-position: center;
  background-repeat: no-repeat;

  object-fit: scale-down;
  height: 50px;
  width: 50px;
}

.chev{
  color: rgba(247, 107, 0, 0.818);
  
}
.bounce-2 {
  animation-name: bounce-2;
  animation-timing-function: ease;
}
@keyframes bounce-2 {
  0%   { transform: translateY(0); }
  50%  { transform: translateY(-50px); }
  100% { transform: translateY(0); }
}