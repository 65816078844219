body{
  
  font-family: Arial, sans-serif;
}

.background-image{
  height: 100vh;
  width: 100vw;
  min-width: None;
  margin-top: 0px;

  background-position: center;
  background-repeat: no-repeat;
  background-size:auto;
  
}


.background{
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

