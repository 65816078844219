.main{
  width: 85vw;
  height: 100vh;
  float: right;
  overflow-x: none;
  overflow-y: scroll;
  z-index: 0;
  overflow-x: hidden;
}

@media only screen and (max-width: 600px){
  .main{
    max-width: 75vw;
    overflow-x: hidden;
  }
}

.page_content {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  max-width: 50vw;
  height: 100%;
  margin: auto;
  padding: 10%;
  background-color: rgba(15, 15, 15, 0.69);
  border-radius: 15pt;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.37);
}

.page_content>.page-title {
  position: relative;
  font-family: 'Courier New', Courier, monospace;
  font-size: large;
  bottom: 7%;
  right: 10%;
}
.fill-screen{
  width:85vw;
  height: 100vh;
}

.send-to-back{
  z-index: -1;
}

.pdf-view{
  width:100%;
  height: 90vh;
}

.my-name{
  position: absolute;
  display: flex;
  flex-direction: column;
  font-size: 10vw;
  justify-content: center;
  align-items: center;
  width: fit-content;
  top: 35%;
  margin-left: 20%;
  margin-right: auto;
  z-index: -1;  
}

.sub-title{
  position: relative;
  font-size: 1.7vw;
  display: inline-block;
  left:50%;
}

@media only screen and (max-width: 600px){
  .sub-title{
    left:30%;
  }
}

@media only screen and (max-width: 600px){
  .page-title{
    top: 0;
    left: 10%;
  }
}

.page-description{
  position: relative;
  width: 80%;
  top:-2%;
  left: 20pt;
  margin-bottom: 20pt;
}

.medium-container{
  display: block;
  border: 1px solid orange;
  max-width: 50%;
  max-height: 50vh;
  border-radius: 10pt;
  background-color: rgba(0, 0, 0, 0.575);
  backdrop-filter: blur(10px);
  top: 20%;
  padding: 10pt;
  margin-left: auto;
  margin-right: auto;
  
  .text{
    width: 90%;
    text-align: center;
  }
}

.hide{
  display: none;
}
.flex-col{
  display: flex;
  flex-direction: column;
}

.scroll-vertical{
  overflow-y: scroll;
}

.text-container70{
  margin:auto;
  width: 70%;
}

.text-container80{
  margin:auto;
  width: 80%;
}

.center-horizontal{
  margin-left: auto;
  margin-left: auto;
  text-align: center;
}

.margin-l10pt{
  margin-left:10pt;
}

.margin-l20pt{
  margin-left:20pt;
}

.vertical-margin10{
  margin-top: 10%;

}
.vertical-margin20{
  margin-top: 20%;
}

.margin_t20pt{
  margin-top: 20pt;
}

.margin_t40pt{
  margin-top: 40pt;
}

.margin_r20pt{
  margin-right: 20pt;
}

.margin_b20pt{
  margin-bottom: 10%;
}



.padding_b10{
  padding-bottom: 10pt;
}

.padding_l20{
  padding-left:20pt;
}

.padding_r20{
  padding-right: 20pt;
}

.orange-text{
  color:orange;
}

.white-text{
  color:white;
}

.small-text{
  font-size: 2vmin;
}

.thin-text{
  font-weight: 100;
}

.thick-text{
  font-weight: bold;
}

.medium-text{
  font-size: medium;
}

.large-text{
  font-size: 1.5vw;
}

.larger-text{
  font-size: 1vw;
}

.x-large-text{
  font-size: 1.5vw;
  margin:1em
}

h1{
  font-weight: 150;
}

h2{
  text-decoration: underline;
  font-weight: 100;
}

.show_button{
  position: relative;
  border: 1px solid black;
  border-radius: 5px;
  padding: 2pt;
  text-decoration: none;
  color: black;
  background-color: rgba(5, 255, 105, 0.661);
  font-size: small;
  top:10pt;
  margin-bottom: 5pt;  
}

.show_button:hover{
  background-color: rgb(164, 125, 53);
}

.list-item{
  background-image: linear-gradient(gray, rgba(81, 81, 81, 0.632));
  border: 1px solid rgba(255, 0, 247, 0.215);
  border-radius: 2pt;
  width: 40vw;
  height: 2vh;
  margin: auto;
  margin-bottom: 2pt;
  display: flex;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 10pt;
  cursor: pointer;
}

.drop-down{
  width:40vw;
  margin: auto;
  max-height: 50vh;
  background-color: rgba(40, 40, 40, 0.58);
  padding: 10pt;
  margin-bottom: 5pt;
}

@media only screen and (max-width: 600px){
  .list-item{
    height: fit-content;
    max-height: 5vh;
    width: 50vw;
  }
  .drop-down{
    max-height: 30vh;
  }
}

.float-left {
  float: left;
}

.width_80{
  width: 80%;
}