
.uni-logo{
  margin-left: 20pt;
  width: 20%;
  img{
    width:10vw;
    margin: auto;
  }
}

@media only screen and (max-width: 600px){
  .uni-logo{
    display: none;
  }
}

.subjects{
  display: inline-block;
  li{
    line-height: 20px;
  }
}

.flex-2-row{
  display: flex;
  flex-direction: row;
}